/* eslint-disable consistent-return */

class CheckInTimeout {
  static CHECK_IN_EXPIRATION =
    window.ENV.check_in_expiration_minutes * 60 * 1000;

  static INTERVAL_DELAY = window.ENV.check_in_timeout_interval_seconds * 1000;

  constructor() {
    this.checkExpiration = this.checkExpiration.bind(this);
    this.updateLastEventTimestamp = this.updateLastEventTimestamp.bind(this);
    this.#initEvents();
    this.updateLastEventTimestamp();
  }

  #initEvents() {
    const $bodyWithTimeout = $("body.with-timeout");

    if ($bodyWithTimeout.length) {
      $bodyWithTimeout
        .on("click", this.updateLastEventTimestamp)
        .on("touchstart", this.updateLastEventTimestamp)
        .on("scroll", this.updateLastEventTimestamp)
        .on("keydown", this.updateLastEventTimestamp);

      this.timer = setInterval(
        this.checkExpiration,
        CheckInTimeout.INTERVAL_DELAY,
      );
    }
  }

  checkExpiration() {
    const lastEventTimestampDelta = Date.now() - this.lastEventTimestamp;
    if (lastEventTimestampDelta >= CheckInTimeout.CHECK_IN_EXPIRATION) {
      return this.onExpired();
    }
  }

  updateLastEventTimestamp() {
    this.lastEventTimestamp = Date.now();
  }

  // eslint-disable-next-line class-methods-use-this
  onExpired() {
    const logoutLink = jQuery("<a/>", {
      href: "/patient_session?timeout=true",
      "data-method": "delete",
    });
    return $.rails.handleMethod(logoutLink);
  }
}

export function initCheckInTimeout() {
  window.CheckInTimeout = CheckInTimeout;
  $(() => new CheckInTimeout());
}
