angular.module("check-in")
  .constant("HTTP_TIMEOUT", window.ENV.ajax_timeout_in_seconds * 1000)
  .config(($httpProvider) => {
    const getToken = () => {
      const el = document.querySelector("meta[name=csrf-token]");
      if (el) {
        return el.getAttribute("content");
      }
      return undefined;
    };

    const token = getToken();
    if (token) {
      angular.extend($httpProvider.defaults.headers.common, {
        "X-CSRF-TOKEN": token,
        "X-Requested-With": "XMLHttpRequest",
      });
    }
  });
