const CHECKED_FINDING_CHECKBOX_SELECTOR =
  "input:checked[data-finding-checkbox]";
const ERROR_FIELD_CLASS = "field-with-errors";
const FINDING_CHECKBOX_SELECTOR = "input[data-finding-checkbox]";
const FORM_ID = "edit_structured_hpi_form";
const NON_CHECKBOX_SELECTOR =
  "input:not([type=checkbox]):not([type=hidden]), select";
const OPTIONS_ARRAY_HIDDEN_FIELD_SELECTOR =
  "input[name*=selected_options_array]";

const hasCheckedOptions = (sentence) => !!sentence.querySelector(CHECKED_FINDING_CHECKBOX_SELECTOR);

const errorElement = (sentence) => (sentence.querySelector(".field-error"));

const checkboxLabels = (sentence) => [...sentence.querySelectorAll(FINDING_CHECKBOX_SELECTOR)]
    .map((checkbox) => checkbox.closest(".field-unit"));

const showError = (sentence) => {
  sentence.classList.add(ERROR_FIELD_CLASS);
  errorElement(sentence).classList.remove("hidden");
  checkboxLabels(sentence).forEach((label) => label.classList.add(ERROR_FIELD_CLASS));
};

const hideError = (sentence) => {
  sentence.classList.remove(ERROR_FIELD_CLASS);
  errorElement(sentence).classList.add("hidden");
  checkboxLabels(sentence).forEach((label) => label.classList.remove(ERROR_FIELD_CLASS));
};

const getSelectFields = (finding) => [...finding.querySelectorAll("select")];

const getSelectFieldValues = (finding) => getSelectFields(finding).map((field) => field.value);

const notACheckbox = (inputElement) => inputElement.tagName !== "INPUT" ||
    inputElement.attributes.type.value !== "checkbox";

const getHiddenField = (finding) => finding.querySelector(OPTIONS_ARRAY_HIDDEN_FIELD_SELECTOR);

const setHiddenFieldValue = (finding) => {
  const hiddenField = getHiddenField(finding);
  if (!hiddenField) { return; }

  if (finding.querySelector(FINDING_CHECKBOX_SELECTOR).checked) {
    hiddenField.value = JSON.stringify(getSelectFieldValues(finding));
  } else {
    hiddenField.value = "[]";
  }
};

const populateSelectFields = (finding) => {
  const hiddenField = getHiddenField(finding);
  if (hiddenField) {
    const selects = getSelectFields(finding);
    const values = JSON.parse(hiddenField.value);
    values.forEach((value, i) => {
      selects[i].value = value;
    });
  }
};

const updateCheckboxChecked = (finding) => {
  const checkbox = finding.querySelector(FINDING_CHECKBOX_SELECTOR);
  if (checkbox) {
    const nonCheckboxes = [...finding.querySelectorAll(NON_CHECKBOX_SELECTOR)];
    const fieldsWithValues = nonCheckboxes.filter((field) => field.value.length > 0);
    checkbox.checked = fieldsWithValues.length > 0;
  }
};

const initInputListeners = (finding) => {
  finding.addEventListener("input", (event) => {
    if (notACheckbox(event.target)) {
       updateCheckboxChecked(finding);
    }
    setHiddenFieldValue(finding);
  });
};

const initFindings = (form) => {
  form.querySelectorAll(".finding-field-group").forEach((finding) => {
    populateSelectFields(finding);
    initInputListeners(finding);
  });
};

const initSubmitHandler = (form) => {
  form.querySelector("button").addEventListener("click", (event) => {
    let hasErrors = false;
    form.querySelectorAll("[data-sentence]").forEach((sentence) => {
      if (!hasCheckedOptions(sentence)) {
        hasErrors = true;
        showError(sentence);
      } else {
        hideError(sentence);
      }
    });
    if (hasErrors) {
      event.preventDefault();
      window.scroll(0, form.offsetTop);
    }
  });
};

export const initStructuredHpi = () => {
  const form = document.getElementById(FORM_ID);
  if (form) {
    initSubmitHandler(form);
    initFindings(form);
  }
};
