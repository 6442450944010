angular.module("check-in").directive("validateForm", (ClientValidator) => {
  const formElementsSelector = [
    ".checkable-collection",
    ".formbuilder-checkbox-group",
    ".formbuilder-number",
    ".formbuilder-radio-group",
    ".formbuilder-select",
    ".formbuilder-text",
    ".field-unit.boolean",
    ".field-unit.email",
    ".field-unit.select",
    ".field-unit.string",
  ].map((cls) => `${cls}:not(.not-validated)`).join(", ");

  const getErrorMessageKey = (invalidComponent, validateMessage) => {
    const invalidInputs = invalidComponent.find(":invalid");

    if (invalidInputs[0].validity.valueMissing) {
      const errorMessageKey =
        invalidComponent
          .find(`[${ClientValidator.INPUT_ERROR_MESSAGE_CLASS}]`)
          .attr(ClientValidator.INPUT_ERROR_MESSAGE_CLASS);
      return errorMessageKey || validateMessage || "field_error_default";
    }
      return "field_error_invalid";
  };

  const initForm = (form) => {
    form.find("input.required, select.required, textarea.required")
      .attr("required", true);
    const formErrorMessageElement = ClientValidator.getErrorMessageElement(
      ClientValidator.FORM_ERROR_MESSAGE_CLASS,
      "",
    );
    form.prepend(formErrorMessageElement);
  };

  const addErrorMessageForComponents = (
    validateMessage,
    invalidComponents,
  ) => {
    invalidComponents.each(function () {
      const invalidComponent = $(this);
      const errorMessageKey = getErrorMessageKey(
        invalidComponent,
        validateMessage,
      );
      const message = ClientValidator.translate(errorMessageKey);
      ClientValidator.updateValidationMessage(invalidComponent, message);
    });
  };

  return {
    restrict: "A",
    link: (scope, elem, attrs) => {
      const { validateForm, validateMessage } = attrs;
      const form = $(`#${validateForm}`);
      initForm(form);
      elem.on("click", (event) => {
        form.removeClass("invalid");
        form.find(".invalid").removeClass("invalid");
        const invalidInputs = form.find(":invalid");
        if (form.length > 0 && invalidInputs.length > 0) {
          event.stopImmediatePropagation();
          event.preventDefault();
          const invalidComponents =
            invalidInputs.closest(formElementsSelector);
          addErrorMessageForComponents(validateMessage, invalidComponents);
          form.find(`.${ClientValidator.FORM_ERROR_MESSAGE_CLASS}`)
            .text(ClientValidator.translate("form_error"));
          form.addClass("validated invalid");
          $("html, body").scrollTop(0);
        }
      });
    },
  };
});
