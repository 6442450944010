/* eslint-disable class-methods-use-this, consistent-return */
import { trackEvent, eventNames } from "utils/EventsTracking";
import initLaddaButton from "./initLaddaButton";

class CheckInForm {
  static ERROR_PAGE_PATH = "/500";

  static FORM_SELECTOR = ".check-in-form";

  static OFFLINE_FORM_RETRY_DELAY =
    window.ENV.offline_form_retry_seconds * 1000;

  static OFFLINE_SUBMIT_EXCEPTION_CLASS = "CheckInFormTimeout";

  static OFFLINE_SUBMIT_FLASH_ERROR_PARTIAL = `\
    <div class="flash offline-submit-flash-error">
      <div class="flash-error">
        There was a connectivity issue. Please try submitting again.
      </div>
    </div>\
  `;

  static RETURN_KEY_CODE = 13;

  static SUBMIT_DELAY = 250;

  constructor() {
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.disableReturnKey = this.disableReturnKey.bind(this);
    this.#initEvents();
  }

  #initEvents() {
    return $("body")
      .on("submit", CheckInForm.FORM_SELECTOR, this.handleFormSubmit)
      .on("keydown", CheckInForm.FORM_SELECTOR, this.disableReturnKey);
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;
    const self = this;
    const $form = $(form);

    this.#setLoadingState(form);

    if (!$form.data("remote") && !$form.is("[ladda-ignore]")) {
      return setTimeout(
        () => self.#submitForm(form),
        CheckInForm.SUBMIT_DELAY,
      );
    }
  }

  #submitForm(form) {
    form.submit();

    return this.#waitForFailedSubmit(form);
  }

  #setLoadingState(form) {
    $(".offline-submit-flash-error").remove();
    const buttons = $(form).find(".ladda-button");
    return (() => {
      const result = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const button of Array.from(buttons)) {
        const ladda = initLaddaButton(button);
        result.push(ladda.start());
      }
      return result;
    })();
  }

  disableReturnKey(e) {
    if (e.which === CheckInForm.RETURN_KEY_CODE) {
      const focusedInput = document.activeElement;
      this.#selectNextInput(focusedInput);
      return false;
    }
  }

  #selectNextInput(input) {
    const inputs = $(input).closest("form").find(":input[type!='hidden']");
    return inputs.eq(inputs.index(input) + 1).focus();
  }

  #waitForFailedSubmit(form) {
    if (CheckInForm.OFFLINE_FORM_RETRY_DELAY > 0) {
      return setTimeout(
        () => {
          form.submit();
          trackEvent(eventNames.OFFLINE_SUBMIT_ATTEMPT, { current_path: window.location.pathname });
          return this.#waitForFailedSubmitRetry(form);
        },
        CheckInForm.OFFLINE_FORM_RETRY_DELAY,
      );
    }
  }

  #waitForFailedSubmitRetry() {
    const {
      OFFLINE_SUBMIT_FLASH_ERROR_PARTIAL,
      OFFLINE_FORM_RETRY_DELAY,
    } = CheckInForm;

    return setTimeout(
      () => {
        const currentPath = window.location.pathname;
        trackEvent(eventNames.OFFLINE_SUBMIT_EXCEPTION_MESSAGE, { current_path: currentPath });
        $(".button-primary").before(OFFLINE_SUBMIT_FLASH_ERROR_PARTIAL);
        return window.Ladda.stopAll();
      },
      OFFLINE_FORM_RETRY_DELAY,
    );
  }
}

export function initCheckInForm() {
  window.CheckInForm = CheckInForm;
  $(() => new CheckInForm());
}
