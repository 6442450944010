angular.module("check-in").directive("epiNewAllergyForm", () => ({
  restrict: "A",
  controller: ($scope, allergyFactory) => {
    let allergyForm = null;

    const resetAllergyForm = () => {
      allergyForm = {
        name: null,
        id: null,
        symptoms: null,
        severities: null,
      };
    };

    const addAllergySuccess = (symptoms, severities) => {
      const { id, name } = allergyForm;
      $scope.allergies.push({
        id, name, symptoms, severities,
      });
      resetAllergyForm();
    };

    resetAllergyForm();

    $scope.validAllergy = () => allergyForm.id !== null;

    $scope.allergySelected = (name, id) => {
      allergyForm = { ...allergyForm, name, id };
      $scope.$apply();
      window.Ladda.stopAll();
    };

    $scope.addAllergy = (symptomId, symptom, severityId, severity) => {
      allergyFactory.addAllergy(allergyForm.id, symptomId, severityId);
      addAllergySuccess([symptom], [severity]);
      $scope.$apply();
    };

    $scope.allergyInvalidated = () => {
      resetAllergyForm();
      $scope.$apply();
    };
  },
  link: (scope, elem) => {
    const resetAddAllergyModal = () => {
      elem.find("#new_allergy_form_allergy_id").val("");
      elem.find("#new_allergy_form_name").val("");
      elem.find("#new_allergy_form_symptom").val("");
      elem.find("#new_allergy_form_severity").val("");
      elem[0].reset();
      $.magnificPopup.close();
    };

    elem.on("submit", (e) => {
      e.preventDefault();
      const symptomId = elem.find("#new_allergy_form_symptom").val();
      const symptom = elem.find("#new_allergy_form_symptom option:selected")
        .text();
      const severityId = elem.find("#new_allergy_form_severity").val();
      const severity = elem.find("#new_allergy_form_severity option:selected")
        .text();
      scope.addAllergy(symptomId, symptom, severityId, severity);
      resetAddAllergyModal();
    });
  },
}));
