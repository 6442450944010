angular.module("check-in").controller(
  "CustomConsentController",
  ($scope, $timeout) => {
    const PATIENT_SIGNING = "patient";

    $scope.signingAsPatient = () => $scope.signerType === PATIENT_SIGNING;

    $scope.submitConsentForm = () => {
      $timeout(() => $("#new_signed_custom_consent").submit());
    };
  },
);
