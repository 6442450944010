/* eslint-disable camelcase */

angular.module("check-in").controller("CrafftController", ($scope) => {
  $scope.showLongForm = () => {
    const {
      drank_alcohol_days,
      used_marijuana_days,
      used_other_drugs_days,
    } = $scope;
    const sum = parseInt(drank_alcohol_days, 10) +
              parseInt(used_marijuana_days, 10) +
              parseInt(used_other_drugs_days, 10);

    return sum > 0;
  };
});
