import isEmpty from "lodash/isEmpty";
import initLaddaButton from "../initLaddaButton";

angular.module("check-in").directive("signatureModal", () => ({
    restrict: "A",
    link: (scope, elem, attrs) => {
      elem.find("input").addClass("signature-output");
      const signaturePad = elem.find(".signature-pad");
      const onSuccessCallback = scope.$eval(attrs.onSuccessCallback);
      const closeButton = !scope.$eval(attrs.disableModalClose);
      const laddaButton = elem.find(".ladda-button")[0];

      const openModal = () => {
        const properties = window.Modal.buildProperties({
          items: {
            src: "#signature-modal",
            type: "inline",
          },
          showCloseBtn: closeButton,
        });
        $.magnificPopup.open(properties);
      };

      const validateSignature = () => {
        const plugin = signaturePad.data("plugin-signaturePad");
        scope.signatureInstructions = JSON.parse(plugin.getSignatureString());
        scope.signatureSigned = !isEmpty(scope.signatureInstructions);
      };

      scope.saveSignature = () => {
        validateSignature();

        if (scope.signatureSigned && onSuccessCallback) {
          const ladda = initLaddaButton(laddaButton);
          ladda.start();
          onSuccessCallback();
        }
      };

      scope.clearSignature = () => {
        signaturePad.data("plugin-signaturePad").clearCanvas();
        scope.signatureInstructions = "";
        scope.signatureSigned = false;
      };

      signaturePad.signaturePad({
        drawOnly: true,
        bgColour: "#EFF1F2",
        lineTop: 150,
        lineColour: "#A8ACAF",
        lineMargin: 23,
        output: ".signature-output",
        validateFields: false,
        onDrawEnd: () => {
          scope.$apply(() => validateSignature());
        },
      });

      if (scope.openModalOnLoad) {
        angular.element(document).ready(() => openModal());
      }
    },
  }));
