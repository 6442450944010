import ReactDOM from "react-dom";
import isObject from "lodash/isObject";
import ClientLogger from "./ClientLogger";

const initClientScreenLogger = () => {
  localStorage.logToClientScreenMessages = "[]";
  const randomString = () => (Math.random() + 1).toString(36).substring(3);

  const logToClientScreen = (text) => {
    const messages = JSON.parse(localStorage.logToClientScreenMessages || "[]");
    messages.push({ text, id: randomString() });
    localStorage.logToClientScreenMessages = JSON.stringify(messages);
    document.dispatchEvent(new CustomEvent("addClientLoggerMessage"));
  };

  const container = document.createElement("div");
  ReactDOM.render(<ClientLogger />, container);
  document.addEventListener("DOMContentLoaded", () => {
    document.body.appendChild(container);
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent("addClientLoggerMessage"));
    }, 1000);
  });

  const stringifyArgs = (args) => (
    args.map((arg) => {
      if (Array.isArray(arg)) {
          return `Array ${JSON.stringify(arg)}`;
      }

      if (isObject(args)) {
         return `Object ${JSON.stringify(arg)}`;
      }
      return arg.toString();
    }).join(", ")
  );

  window.console = ((oldConsole) => (
    {
      log: (...args) => {
        oldConsole.log(...args);
        logToClientScreen(`📃 ${stringifyArgs(args)}`);
      },
      info: (...args) => {
        oldConsole.info(...args);
        logToClientScreen(`ℹ️ ${stringifyArgs(args)}`);
      },
      warn: (...args) => {
        oldConsole.warn(...args);
        logToClientScreen(`⚠️ ${stringifyArgs(args)}`);
      },
      error: (...args) => {
        oldConsole.error(...args);
        logToClientScreen(`🛑 ${stringifyArgs(args)}`);
      },
    }
  ))(window.console);
};

export default initClientScreenLogger;
