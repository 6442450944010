import every from "lodash/every";
import some from "lodash/some";

angular.module("check-in")
  .controller("CurrentMedicationsController", ($scope, $filter) => {
    const allNotTaking = (medications) => every(
        medications,
        ({ currentlyTaking }) => currentlyTaking === "1",
      );

    const anyTaking = (medications) => some(
        medications,
        ({ currentlyTaking }) => currentlyTaking === "0",
      );

    const setShowTakingAnyMedicationsQuestion = (medications) => {
      if (medications.length === 0) {
        $scope.showTakingAnyMedicationsQuestion = true;
      } else if (allNotTaking(medications)) {
        whenAllNotTaking();
      } else {
        if (anyTaking(medications)) {
          $scope.takingAnyMedications = "true";
        }
        $scope.showTakingAnyMedicationsQuestion = false;
      }
    };

    const whenAllNotTaking = () => {
      if ($scope.showTakingAnyMedicationsQuestion !== true) {
          $scope.takingAnyMedications = null;
        }
      $scope.showTakingAnyMedicationsQuestion = true;
    };

    $scope.$watch("medications.length", (medicationsCount) => {
      $scope.medicationsCount = medicationsCount;
      if (medicationsCount === 0) {
        $scope.medicationsLoaded = true;
      }
    });

    $scope.$on("ngRepeatFinished", (_ngRepeatFinishedEvent) => {
      $scope.medicationsLoaded = true;
    });

    $scope.$watch("medications", setShowTakingAnyMedicationsQuestion, true);

    $scope.getMedications = () => $filter("orderBy")($scope.medications, "name");
  });
