angular
  .module("check-in")
  .controller("PersonalInformationController", ($scope, $window) => {
    const getAddress2 = () => $scope.personalInformation.address2;

    const buildPatientAddressForDisplay = (personalInformation) => {
      const { address1, city, state, zip } = personalInformation;

      const address = [address1, getAddress2()].join(" ");
      const location = [state, zip].join(" ");

      return [address, city, location]
        .map((item) => item?.trim())
        .filter((item) => !!item)
        .join(", ");
    };

    const isEmailChanged = () => {
      const {
        personalInformation: { email },
        initialEmail,
      } = $scope;

      return email?.length > 0 && email !== initialEmail;
    };

    $scope.$watchCollection("personalInformation", (personalInformation) => {
      const { guarantoraddresssameaspatient, referralsourceid } = personalInformation;

      if (guarantoraddresssameaspatient === "true") {
        $scope.personalInformation.guarantoraddresssameaspatient = true;
      } else if (guarantoraddresssameaspatient === "false") {
        $scope.personalInformation.guarantoraddresssameaspatient = false;
      }

      $scope.personalInformation.hasReferralSource = referralsourceid && referralsourceid !== "";

      $scope.patientAddressForDisplay = buildPatientAddressForDisplay(personalInformation);
    });

    $scope.showBillingInformation = () => {
      const { address1, city, state, zip } = $scope.personalInformation;

      return address1 && city && state && zip;
    };

    $scope.guarantorRelationshipIsSelf = () => {
      const { guarantorrelationshiptopatient } = $scope.personalInformation;

      return guarantorrelationshiptopatient === $window.ENV.guarantorRelationshipSelfId;
    };

    $scope.guarantorAddressIsSameAsPatient = () => {
      const { guarantoraddresssameaspatient } = $scope.personalInformation;

      return guarantoraddresssameaspatient === true;
    };

    $scope.showReferralSource = () =>
      $scope.showReferralSourceQuestion === true && $scope.personalInformation.hasReferralSource !== true;

    $scope.providesEmail = () => $scope.personalInformation.provide_email !== "false";

    $scope.editableEmail = () => !$scope.showWarningBeforeEmailEdit && $scope.emailEditionAllowed();

    $scope.emailEditionAllowed = () => !$scope.readOnlyEmail;

    $scope.hasEmail = () => !!$scope.initialEmail;

    $scope.showYesNo = () => !$scope.readOnlyEmail;

    $scope.shouldConfirmEmail = () => $scope.editableEmail() && $scope.providesEmail() && isEmailChanged();

    $scope.emergencyContactInformationIsRequired = () => {
      const {
        contacthomephone,
        contactmobilephone,
        contactname,
        contact_first_name: contactFirstName,
        contact_last_name: contactLastName,
      } = $scope.personalInformation.emergency_contact_information;

      return Boolean(contacthomephone || contactmobilephone || contactname || contactFirstName || contactLastName);
    };

    $scope.genderIdentityAdditionalGenderSelected = () => {
      const { genderidentity } = $scope.personalInformation;
      const otherOptionRegex = new RegExp($window.ENV.genderIdentityOtherOption, "i");

      return otherOptionRegex.test(genderidentity);
    };

    $scope.sexualOrientationSomethingElseSelected = () => {
      const { sexualorientation } = $scope.personalInformation;
      const otherOptionRegex = new RegExp($window.ENV.sexualOrientationOtherOption, "i");

      return otherOptionRegex.test(sexualorientation);
    };
  });
