import { trackEvent, eventNames } from "utils/EventsTracking";

/* global Modal, Ladda */
angular.module("check-in")
  .constant("MAX_SWIPE_ATTEMPTS", 5)
  .constant("MAX_SWIPE_DETECTION_ATTEMPTS", 3)
  .constant("MODAL_OPENER_SELECTOR", ".payment-button")
  .constant("PAYMENT_SIMULATION", process.env.PAYMENT_SIMULATION || false)
  .constant("RECOVERABLE_HARDWARE_ERROR", "recoverable_hardware_error")
  .constant("SWIPE_NOT_DETECTED_EVENT_TIMEOUT_MS", process.env.SWIPE_NOT_DETECTED_EVENT_TIMEOUT_MS || 15000)
  .constant("UNRECOVERABLE_HARDWARE_ERROR", "unrecoverable_hardware_error")
  .controller(
    "SwipeCreditCardController",
    (
      $scope,
      $controller,
      $timeout,
      $http,
      $window,
      MAX_SWIPE_DETECTION_ATTEMPTS,
      MAX_SWIPE_ATTEMPTS,
      MODAL_OPENER_SELECTOR,
      PAYMENT_SIMULATION,
      RECOVERABLE_HARDWARE_ERROR,
      SWIPE_NOT_DETECTED_EVENT_TIMEOUT_MS,
      UNRECOVERABLE_HARDWARE_ERROR,
    ) => {
      $controller("CardReaderStatusController", { $scope });

      const startWaitingForSwipeTimer = () => {
        removeWaitingForSwipeTimer();

        $scope.waitingForSwipePromise = $timeout(
          () => {
            trackEvent(eventNames.SWIPE_NOT_DETECTED, {
              timeout_used_in_ms: SWIPE_NOT_DETECTED_EVENT_TIMEOUT_MS,
            });
            $scope.$broadcast("swipeIsNotDetected");
          },
          SWIPE_NOT_DETECTED_EVENT_TIMEOUT_MS,
        );
      };

      const removeWaitingForSwipeTimer = () => {
        if ($scope.waitingForSwipePromise) {
          $timeout.cancel($scope.waitingForSwipePromise);
          $scope.waitingForSwipePromise = undefined;
        }
      };

      const handlePaymentStatus = (status) => {
        const closeButton = $(".swipe-card-modal button.mfp-close");

        switch (status) {
          case $scope.PAYMENT_STATUS.WAITING_FOR_SWIPE:
            closeButton.show();
            if (PAYMENT_SIMULATION !== "true") {
              startWaitingForSwipeTimer();
            }
            break;
          case $scope.PAYMENT_STATUS.SENDING:
            closeButton.hide();
            break;
          case $scope.PAYMENT_STATUS.FAILURE:
            closeButton.show();
            break;
          default:
            closeButton.hide();
            removeWaitingForSwipeTimer();
        }
      };

      const paymentCreationSuccess = (_response) => {
        $scope.paymentStatus = $scope.PAYMENT_STATUS.SUCCESS;
      };

      const paymentCreationError = (response) => {
        $scope.errorMessage = response.data.error_message;

        if (
          response.data.recoverable === true &&
            $scope.swipeAttempts < MAX_SWIPE_ATTEMPTS
        ) {
          $scope.paymentStatus = $scope.PAYMENT_STATUS.WAITING_FOR_SWIPE;
          $scope.swipeAttempts++;
        } else if ($scope.swipeAttempts >= MAX_SWIPE_ATTEMPTS) {
          $scope.tooManyAttempts = true;
          $scope.paymentStatus = $scope.PAYMENT_STATUS.FAILURE;
        } else if ($scope.updateValidationMessageOnFailure) {
          $scope.updateValidationMessageOnFailure(response.data);
          $scope.paymentStatus = $scope.PAYMENT_STATUS.FAILURE;
        } else {
          $scope.tooManyAttempts = false;
          $scope.paymentStatus = $scope.PAYMENT_STATUS.FAILURE;
        }
      };

      Modal.onOpen(MODAL_OPENER_SELECTOR, () => {
        $window.addEventListener("orientationchange", $scope.$apply);
      });

      Modal.onClose(MODAL_OPENER_SELECTOR, () => {
        $window.removeEventListener("orientationchange", $scope.$apply);
        $scope.errorMessage = "";
        $scope.paymentStatus = null;
      });

      $scope.orientationClass = () => `orientation${$window.orientation || 0}`;

      $scope.hardwareError = null;
      $scope.swipeDetectionAttempts = 0;
      $scope.swipeAttempts = 0;
      $scope.tooManyAttempts = false;

      $scope.PAYMENT_STATUS = {
        WAITING_FOR_SWIPE: 0,
        SENDING: 1,
        SUCCESS: 2,
        FAILURE: 3,
      };

      $scope.$watch("paymentStatus", handlePaymentStatus);

      $scope.isRecoverableHardwareError = () => (
        $scope.hardwareError === RECOVERABLE_HARDWARE_ERROR
      );

      $scope.isUnrecoverableHardwareError = () => (
        $scope.hardwareError === UNRECOVERABLE_HARDWARE_ERROR
      );

      $scope.isHardwareError = () => !!$scope.hardwareError;

      $scope.isWaitingForSwipe = () => (
        $scope.paymentStatus === $scope.PAYMENT_STATUS.WAITING_FOR_SWIPE
      );

      $scope.isSending = () => (
        $scope.paymentStatus === $scope.PAYMENT_STATUS.SENDING
      );

      $scope.isSuccess = () => (
        $scope.paymentStatus === $scope.PAYMENT_STATUS.SUCCESS
      );

      $scope.isFailure = () => (
        $scope.paymentStatus === $scope.PAYMENT_STATUS.FAILURE
      );

      $scope.initiateSwipeWorkflow = () => {
        $scope.$on("cardReaderConnected", $scope.startWaitingForSwipe);
        $scope.$on(
          "cardReaderConnectionFailed",
          $scope.onReaderConnectionFailure,
        );
        $scope.$on("swipeIsNotDetected", $scope.onSwipeDetectionFailure);

        $scope.tryToConnectToCardReader();
      };

      $scope.startWaitingForSwipe = () => {
        $scope.swipeDetectionAttempts += 1;
        $scope.paymentStatus = $scope.PAYMENT_STATUS.WAITING_FOR_SWIPE;
      };

      $scope.onReaderConnectionFailure = () => {
        $(".swipe-card-modal button.mfp-close").hide();
        $scope.hardwareError = UNRECOVERABLE_HARDWARE_ERROR;
      };

      $scope.onSwipeDetectionFailure = () => {
        $scope.paymentStatus = null;

        if ($scope.swipeDetectionAttempts < MAX_SWIPE_DETECTION_ATTEMPTS) {
          $scope.hardwareError = RECOVERABLE_HARDWARE_ERROR;
        } else {
          $scope.hardwareError = UNRECOVERABLE_HARDWARE_ERROR;
        }
      };

      $scope.tryDetectingSwipeAgain = () => {
        $scope.startWaitingForSwipe();
        $scope.hardwareError = null;
        trackEvent(eventNames.TRY_DETECT_SWIPE_AGAIN);
      };

      $scope.onCreditCardSwipe = (path, data, cardReaderSerialNumber, ipadId) => {
        removeWaitingForSwipeTimer();

        const swipeIsExpected = $scope.isWaitingForSwipe();
        trackEvent(eventNames.SWIPE_DETECTED, {
          expected_swipe: swipeIsExpected,
          card_reader_serial_number: cardReaderSerialNumber,
          ipad_id: ipadId,
        });

        if (swipeIsExpected) {
          $scope.paymentStatus = $scope.PAYMENT_STATUS.SENDING;
          $scope.sendCreditCardData(path, data);
        }
      };

      $scope.sendCreditCardData = (path, data) => {
        $http.post(path, JSON.stringify(data))
          .then(paymentCreationSuccess, paymentCreationError);
      };

      $scope.submitForm = () => {
        $timeout(() => {
          if ($scope.signatureForm.$valid) {
            $scope.emailIsInvalidError = false;
            $("#signature_form").submit();
          } else {
            Ladda.stopAll();
            $scope.emailIsInvalidError = true;
          }
        });
      };

      $scope.simulateCardSwipe = () => {
        if (PAYMENT_SIMULATION === "true") {
          // eslint-disable-next-line no-console
          console.info("Card swipe simulation triggered");
          $scope.readEncryptedCardData(
            {
              "device-ksn": "test",
              "encrypted-magneprint": "test",
              "magneprint-status": "test",
              "encrypted-track-1": "test",
              "encrypted-track-2": "test",
              "device-serial-number": "test",
            },
            "test",
          );
        }
      };
    },
  );
