import some from "lodash/some";

angular.module("check-in").controller("MedicalHistoryController", ($scope) => {
  const setFormEmpty = (answers) => {
    $scope.formEmpty = !some(answers);
  };

  const setShowNextButton = () => {
    $scope.showNextButton = !$scope.formEmpty || $scope.noConditionsBoxSelected;
  };

  $scope.noConditionsChanged = ($event) => {
    $scope.noConditionsBoxSelected = $event.target.checked;
  };

  $scope.noConditionsBoxSelected = false;

  $scope.$watch("answers", setFormEmpty, true);

  $scope.$watchGroup(
    ["formEmpty", "noConditionsBoxSelected"],
    setShowNextButton,
  );

  setFormEmpty($scope.answers);
});
