import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Toolbar from "./Toolbar";
import styles from "./styles";

const LOG_HEIGHT = 200;

const ClientLogger = () => {
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    document.addEventListener("addClientLoggerMessage", (_event) => {
      setMessages(JSON.parse(localStorage.logToClientScreenMessages));
    });
  }, []);

  const clearMessages = () => {
    localStorage.removeItem("logToClientScreenMessages");
    setMessages([]);
  };

  return <LoggerForm clearMessages={clearMessages} messages={messages} />;
};

const Message = ({ text, isLast }) => (
  <>
    <div style={styles.message}>{text}</div>
    {isLast || <hr style={styles.messageDivider} />}
  </>
);

Message.propTypes = {
  text: PropTypes.string.isRequired,
  isLast: PropTypes.bool.isRequired,
};

const messagesPropTypes =
  PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  }));

const Log = ({ messages, collapsed }) => {
  const logRef = useRef();
  useEffect(() => {
    logRef.current.scrollTop = logRef.current.scrollHeight;
  }, [messages]);

  return (
    <div
      ref={logRef}
      style={{
        ...styles.log,
        height: collapsed ? 0 : LOG_HEIGHT,
      }}
    >
      {messages.map((message, index) => (
        <Message
          isLast={index === messages.length - 1}
          key={message.id}
          text={message.text}
        />
      ))}
    </div>
  );
};

Log.propTypes = {
  messages: messagesPropTypes.isRequired,
  collapsed: PropTypes.bool.isRequired,
};

const LoggerForm = ({ messages, clearMessages }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <form style={styles.loggerForm}>
      <Toolbar
        messages={messages}
        onCollapseChange={setCollapsed}
        onTrashCanClick={clearMessages}
      />
      <Log collapsed={collapsed} messages={messages} />
    </form>
  );
};

LoggerForm.propTypes = {
  messages: messagesPropTypes.isRequired,
  clearMessages: PropTypes.func.isRequired,
};

export default ClientLogger;
