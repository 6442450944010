angular.module("check-in")
  .constant("IS_DISCLOSING_MEDICAL_RECORD", "1")
  .controller(
    "ConsentController",
    ($scope, $timeout, IS_DISCLOSING_MEDICAL_RECORD) => {
      const PATIENT_SIGNING = "patient";

      $scope.signingAsPatient = () => $scope.signerType === PATIENT_SIGNING;

      $scope.isDisclosingMedicalRecord = () => $scope.discloseMedicalRecord === IS_DISCLOSING_MEDICAL_RECORD;

      $scope.submitConsentForm = () => {
        $timeout(() => $("#edit_consent_form").submit());
      };
    },
  );
