angular.module("check-in")
  .constant("SWIPE_TRACK_SCANNER_URL", "stBrowser://startCameraScanner")
  .directive("epiTriggerQrCodeScanner", ($window, SWIPE_TRACK_SCANNER_URL) => ({
      restrict: "A",
      link: (_scope, elem) => {
        const openScanner = (e) => {
          e.preventDefault();
          $window.location.href = SWIPE_TRACK_SCANNER_URL;
        };

        elem.on("click", openScanner);
      },
    }));
