import { useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles";

const Copier = ({ messages }) => {
  const inputRef = useRef();
  const [showCheckmark, setShowCheckmark] = useState(false);

  const copyToClipboard = () => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(inputRef.current);
    selection.removeAllRanges();
    selection.addRange(range);
    inputRef.current.setSelectionRange(0, inputRef.current.value.length);
    document.execCommand("copy");
    setShowCheckmark(true);
    setTimeout(() => setShowCheckmark(false), 1000);
  };

  return (
    <>
      <button
        onClick={copyToClipboard}
        style={styles.copyButton}
        type="button"
      >
        <span
          aria-label="Copied"
          role="img"
          style={
              showCheckmark ? {} : styles.checkmarkHidden
            }
        >
          ✓
        </span>
        &nbsp;
        <span aria-label="Copy" role="img">📋</span>
      </button>
      <textarea
        readOnly
        ref={inputRef}
        style={styles.hiddenTextArea}
        value={messages.map((message) => message.text).join("\n\n")}
      />
    </>
  );
};

Copier.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  })).isRequired,
};

const ExpandCollapseTriangle = ({ onChange }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <button
      onClick={() => {
        setCollapsed(!collapsed);
        onChange(!collapsed);
      }}
      style={{
        ...styles.expandCollapseTriangle,
        transform: `rotate(${collapsed ? 90 : 0}deg)`,
      }}
      type="button"
    >
      ▼
    </button>
  );
};

const TrashCan = ({ onClick }) => (
  <button
    onClick={onClick}
    style={styles.trashCan}
    type="button"
  >
    🗑
  </button>
);

ExpandCollapseTriangle.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const Toolbar = ({ messages, onCollapseChange, onTrashCanClick }) => (
  <div style={styles.toolbar}>
    <TrashCan onClick={onTrashCanClick} />
    <Copier messages={messages} />
    <ExpandCollapseTriangle onChange={onCollapseChange} />
  </div>
);

Toolbar.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  })).isRequired,
  onCollapseChange: PropTypes.func.isRequired,
  onTrashCanClick: PropTypes.func.isRequired,
};

export default Toolbar;
